import React from "react";
import SEO from "../../components/seo";
import Blog from "../../components/Blog";
import Par from "../../components/Par";
import H1 from "../../components/h1";

const title = "Fatty Liver";

// Download link - http://www.fbrennangastro.com.au/downloads/gesafattyliver.pdf

export default () => (
	<Blog title={title}>
		<SEO
			title={title}
			keywords={[title, "Causes of fatty liver", "Reversible fatty liver"]}
			type="article"
			description="Fatty liver- what is it, how is it caused and what to do about it. It's reversible!"
		/>
		<H1>{title}</H1>
		<Par>
			Fatty liver disease is an accumulation of fat in the liver. It can lead to serious complications such as
			cirrhosis. It is one of the most common liver diseases in Australia and a common reason for abnormal liver tests
			and ultrasound results.
		</Par>
		<Par>
			Non-alcoholic fatty liver disease (NAFLD) is a common disorder that now affects up to one-third of the adult
			population. It is increasingly common in children.
		</Par>
		<Par>
			Increasing age, obesity, insulin resistance and diabetes are risk factors. Less common causes can include
			pregnancy, some medications (e.g. corticosteroids) and other diseases.
		</Par>
		<Par>
			Excess liver fat is associated with an increased risk of developing liver cirrhosis, diabetes, high blood
			pressure, cardiovascular events or specific cancers. The good news is that fatty liver is reversible.
		</Par>
	</Blog>
);
